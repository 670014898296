
.GuestFieldWrapperOuter {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 35%;
	height: 85%;
}

.GuestFieldWrapperInner, .GuestTextAreaWrapperInner {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	position: relative;
	width: calc(100% - 5px - 0.5vmin);
	height: calc(15% - 5px - 0.5vmin);
	left: 0;
	top: 0;
	padding: calc(2.5px + 0.25vmin);
	margin: 0;
	border-radius: calc(2px + 0.2vmin);
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 0.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
	z-index: 2;
}

.GuestFieldWrapperInner::before, .GuestTextAreaWrapperInner::before {
	position: absolute;
	content: '';
	overflow: hidden;
	width: calc(100% - 5px - 0.5vmin);
	height: calc(100% - 5px - 0.5vmin);
	border-radius: calc(2px + 0.2vmin);
	background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
	z-index: -1;	
}

.GuestFieldLabelWrapper, .GuestTextAreaLabelWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 20%;
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
	border-right: calc(1px + 0.03vmin) solid black;
	color: black;
	font-weight: 400;
}

.GuestFieldInput, .GuestTextArea {
	outline: none;
	box-sizing: border-box;
	width: 80%;
	border: none;
	background-image: none;
	background-color: transparent;
	color: var(--white-color);
	padding-left: 2%;
	transition: 0.5s;
}

.GuestFieldInput::placeholder, .GuestTextArea::placeholder {
	font-size: 1.25vmin;
	font-style: italic;
}

.GuestFieldInput:focus, .GuestTextArea:focus {
	color: var(--very-light-primary-color);
	border-top-right-radius: calc(2px + 0.2vmin);
	border-bottom-right-radius: calc(2px + 0.2vmin);
	border: calc(1px + 0.03vmin) solid var(--very-light-primary-color);
}


.GuestTextAreaWrapperInner {
	flex-direction: column;
	height: calc(75% - 5px - 0.5vmin);
}

.GuestTextAreaLabelWrapper {
	width: 100%;
	height: 15%;
	border-right: none;
	border-bottom: calc(1px + 0.03vmin) solid black;
	background-image: linear-gradient(to bottom right, var(--light-background-color), var(--lighter-background-color));
}

.GuestTextArea {
	resize: none;
	width: 100%;
	height: 85%;
}

.GuestTextAreaWrapperOuter {
	width: 30%;
	height: 85%;
}
