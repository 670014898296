
.GuestQueue {
    width: 100%;
    height: calc(100% - 1px);
	overflow-y: scroll;
	overflow-x: hidden;
}

.GuestCardOuter {
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: calc(80px + 2vmin);
	height: auto;
	border-bottom: calc(1px + 0.15vmin) solid var(--light-background-color);
	color: var(--white-color);
}

.GuestCardInner {
	overflow: hidden;
	box-sizing: border-box;
	min-height: calc(80px + 2vmin);
	width: 100%;
	background-image: linear-gradient(to top, var(--normal-background-color), var(--lighter-background-color));

}

.GuestCardHeader {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	box-sizing: border-box;
	padding-left: 9%;
	padding-right: 3%;
	padding-top: 2%;
	padding-bottom: 2%;
	left: calc(0.50px);
	width: 100%;
	background-image: linear-gradient(to top, var(--dark-background-color), var(--normal-background-color));
	min-height: 30%;
}

.GuestCardComment {
	overflow: hidden;
	box-sizing: border-box;
	margin: 1%;
	padding: 0.45vmin;
	width: calc(100% - 2%);
	min-height: calc(50px + 0.25vmin);
	max-height: calc(80px + 1vmin);
	text-overflow: ellipsis;
	word-wrap: break-word;
	background-image: linear-gradient(to top, var(--light-background-color), var(--very-light-background-color));
	box-shadow: inset 0 0 calc(10px + 0.75vmin) var(--dark-background-color);
	border: calc(1px + 0.03vmin) solid var(--darker-background-color);
}

.GuestCardDetails {
	width: 100%;
	box-sizing: border-box;
	padding-left: 2.5%;
	padding-right: 2.5%;
	padding-top: 1.5%;
	padding-bottom: 1.5%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.GuestCardBody {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	padding-bottom: 0.25vmin;
}

.GuestCardHeader div {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.CardWhiteFont {
	color: var(--white-color);
}

.GuestCardActive .GuestCardHeader {
	background-image: linear-gradient(to top, var(--primary-color), var(--light-primary-color));
	filter: brightness(75%);
}

.GuestCardActive {
	filter: brightness(145%);
}

.StatusIndicatorWrapper {
	position: absolute;
	left: 2%;
	top: 0%;
	width: 3.5%;
	height: 100%;
}

.StatusIndicator {
	width: 100%;
	padding-top: 100%;	
	border-radius: 100%;
}

.RatingStarEntire {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 25%;
}

.RatingStarWrapper {
	width: 18%;
	height: 100%;
	padding-left: 1%;
	padding-right: 1%;
}

.RatingStar {
	width: 100%;
	padding-top: 100%;
	border-radius: 100%;
	background-image: linear-gradient(to top right, var(--light-background-color), var(--very-light-background-color));
	box-shadow: inset 0 0 calc(1px + 0.03vmin) var(--dark-background-color);
	border: calc(1px + 0.03vmin) solid var(--darker-background-color);
}


.ActiveStar {
	background-image: linear-gradient(to top right, var(--white-color), var(--bright-white-color));
}

.InactiveStar {
	background-image: linear-gradient(to top right, var(--light-background-color), var(--very-light-background-color));
}


.StatusActive {
	background-image: linear-gradient(to top, var(--active-color), var(--light-active-color));
	box-shadow: inset 0 0 calc(1px + 0.03vmin) var(--dark-background-color);
}

.StatusInactive {
	background-image: linear-gradient(to top, var(--dark-inactive-color), var(--inactive-color));
	box-shadow: inset 0 0 calc(1px + 0.03vmin) var(--dark-background-color);
}

.LiveIndicator {
	text-align: center;
	font-size: calc(1.3vmin);
	font-weight: 700;
}

.LiveIndicator.Assigned {
	color: var(--primary-color);
}

.LiveIndicator.OffAir {
	color: var(--active-color);
}

.LiveIndicator.OnAir {
	color: var(--inactive-color);
}