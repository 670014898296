
.DemographicsSectionOne {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}


.DemographicsWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	box-sizing: border-box;
	padding-top: calc(5px + 1vh);
	width: 100%;
	height: 100%;
}

.RatingContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 7%;
}

.RatingContainer > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: 6%;
	height: 100%;
	margin-right: 2%;
	margin-left: 2%;
	padding-top: 2%;
	padding-bottom: 2%;
}

.RatingContainer > div:hover {
	filter: brightness(140%);
}

.RatingElement {
	padding-top: 100%;
	border-radius: 100%;
	width: 100%;
}

.RatingActive > div {
	background-color: var(--very-light-primary-color);
}

.RatingInactive > div {
	background-color: var(--light-background-color);
}

.RatingActive.MainAnimation > div {
	animation: primary-background-glow 2.5s ease-in-out infinite alternate;
	transform: rotateZ(360deg);
}

.RatingActive.AltAnimation > div {
	animation: primary-background-glow-alt 2.5s ease-in-out infinite alternate;
	transform: rotateZ(360deg);
}

.RatingGroupPriorityWrapper {
	position: relative;
	overflow: hidden;
	width: calc(25% - 6px - 0.6vmin);
	height: calc(90% - 6px - 0.6vmin);
	padding: calc(3px + 0.3vmin);
	border-radius: calc(10px + 0.15vmin);
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 0.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
	z-index: 2;
}

.RatingGroupPriorityWrapper:before {
	position: absolute;
	content: '';
	overflow: hidden;
	width: calc(100% - 6px - 0.6vmin);
	height: calc(100% - 6px - 0.6vmin);
	border-radius: calc(10px + 0.15vmin);
	background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
	z-index: -1;
}

.PriorityWrapperOuter {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	box-sizing: border-box;
	border: calc(1px + 0.15vmin) solid black;
	border-top-left-radius: calc(10px + 0.15vmin);
	border-top-right-radius: calc(10px + 0.15vmin);
	width: 100%;
	height: 78%;
}

.PriorityWrapperInner {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	flex-grow: 1;
	color: black;
	font-weight: 500;
	border-bottom: calc(1px + 0.15vmin) solid black;
	transition: 0.5s;
}

.PriorityWrapperInner:first-child {
	border-top-left-radius: calc(10px + 0.15vmin);
	border-top-right-radius: calc(10px + 0.15vmin);
}

.PriorityWrapperInner:last-child {
	border-bottom: none;
}

.PriorityWrapperInner:hover {
	filter: brightness(140%);
}

.InactivePriority {
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
}

.ActivePriority {
	background-color: var(--normal-background-color);
	color: var(--very-light-primary-color);
	box-shadow: inset 0 0 calc(10px + 0.75vmin) 0 black;
	animation: primary-text-glow 4.5s ease-in-out infinite alternate;
}

.groupOptionWrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	box-sizing: border-box;
	overflow: hidden;
	width: 100%;
	height: 15%;
	background-image: linear-gradient(to right bottom, var(--lighter-background-color), var(--lighter-background-color), var(--normal-background-color));
	padding-top: 3%;
	padding-bottom: 3%;
	padding-left: 2%;
	padding-right: 2%;
}

.groupOption {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	border-left: calc(1px + 0.03vmin) solid black;
	border-right: calc(1px + 0.03vmin) solid black;
	border-top: calc(2px + 0.06vmin) solid black;
	border-bottom: calc(2px + 0.06vmin) solid black;
}

.groupOption:hover {
	filter: brightness(140%);
}

.RatingGroupPriorityWrapper {
	display: flex;
	flex-direction: column;
}

.groupOption:last-child {
	border-top-right-radius: calc(5px + 0.15vmin);
	border-bottom-right-radius: calc(5px + 0.15vmin);
}

.groupOptionActive {
	filter: brightness(80%);
	box-shadow: inset 0 0 calc(7px + 0.275vmin) black;
}

.groupOption:nth-child(1) {
	background-image: linear-gradient(to top, #555555, #888888);
	border-top-left-radius: calc(5px + 0.15vmin);
	border-bottom-left-radius: calc(5px + 0.15vmin);
	background-color: #555555;
}

.groupOption:nth-child(2) {
	background-image: linear-gradient(to top, #aa0000, #ee0000);
	background-color: #aa0000;
}

.groupOption:nth-child(3) {
	background-image: linear-gradient(to top, #00aa00, #00ee00);
	background-color: #00aa00;
}

.groupOption:nth-child(4) {
	background-image: linear-gradient(to top, #0000aa, #0000ee);
	background-color: #0000aa;
}

.groupOption:nth-child(5) {
	background-image: linear-gradient(to top, #aaaa00, #eeee00);
	background-color: #aaaa00;
}


.UpdateGuestSaveButtonWrapper {
	display: flex; 
	flex-direction: row;
	justify-content: center;
	box-sizing: border-box;
	padding-top: 4%;
	width: 100%;
	height: 15%;
}

/*
.UpdateGuestSaveButton {
	outline: none;
	box-sizing: border-box;
	width: 35%;
	height: 100%;
	background-image: linear-gradient(to top, var(--light-background-color), var(--light-background-color));
	border-radius: calc(5px + 0.35vmin);
	border: calc(1px + 0.03vmin) solid black;
	font-size: 15px;
}*/



.UpdateGuestSaveButton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	width: calc(27% - 1px - 1vmin);
	height: calc(100% - 1vmin);
	padding: 0.5vmin;
	background-image: linear-gradient(to top right, var(--lighter-background-color), var(--very-light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(5px + 0.15vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(5px + 0.15vmin) var(--darker-background-color);
	color: var(--very-dark-background-color);
	transition: 0.25s;
	border: calc(1px + 0.06vmin) solid black;
}

.UpdateGuestSaveButton.changed {
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--light-primary-color), 0 0 calc(15px + 0.7vmin) var(--primary-color);
	color: var(--bright-white-color);
}

.UpdateGuestSaveButton:hover {
	filter: brightness(140%);
}

.UpdateGuestSaveButton.changed:active {
	box-shadow: 0 0 calc(10px + 0.25vmin) black,
				inset 0 0 calc(10px + 0.25vmin) var(--dark-background-color);
	background-image: linear-gradient(to top, var(--dark-background-color), var(--normal-background-color));
	filter: brightness(85%);
	transform: translateY(3px);
}

.UpdateGuestSaveButton:before {
	position: absolute;
	content: '';
	width: calc(100% - 1px - 1vmin);
	height: calc(100% - 1vmin);
	border-top-right-radius: calc(20px + 2vmin) 100%;
	border-top-left-radius: calc(20px + 2vmin) 100%;
	border-bottom-right-radius: calc(20px + 2vmin) 100%;
	border-bottom-left-radius: calc(20px + 2vmin) 100%;
	z-index: -1;
}

.CallerHistoryWrapperOuter {
	position: absolute;
	bottom: 2%;
	right: 1%;
	width: 15%;
	height: 6%;
	transition: 0;
	--section-width: 100%;
	--section-height: 100%;
	--section-padding: 1.0vmin;
	--section-margin: 1.0vmin;
	--section-border: calc(1px + 0.03vmin);
}

.CallerHistoryButton {
	box-sizing: border-box;
	text-overflow: ellipsis;
	white-space: nowrap;
	visibility: visible;
	font-size: 1.3vmin;
	line-height: 1.8vmin;
	background-image: linear-gradient(to top, var(--primary-color), var(--light-primary-color));
	color: var(--white-color);
	width: 100%;
	height: 100%;
}

.CallerHistoryWrapperInner {
	position: absolute;
	box-sizing: border-box;
	overflow: scroll;
	top: var(--section-padding);
	left: var(--section-padding);
	width: calc(100% - 2*var(--section-padding));
	height: calc(100% - 2*var(--section-padding));
	visibility: hidden;
	z-index: 14;
}

.CallerHistoryWrapperOuter {
	overflow: hidden;
}

.CallerHistoryWrapperOuter:hover > .CallerHistoryButton {
	visibility: hidden;
}

.CallerHistoryWrapperOuter:hover > .CallerHistoryWrapperInner {
	visibility: visible;
}

.CallerHistoryWrapperOuter:hover {
	position: absolute;
	box-sizing: border-box;
	width: calc(var(--section-width) - 2 * var(--section-margin));
	height: calc(var(--section-height) - 2 * var(--section-margin));
	padding: var(--section-padding);
	right: var(--section-margin);
	bottom: var(--section-margin);
	border: var(--section-border) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(20px + 2.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
	z-index: 12;
	transition: 0.5s;
}

.CallerHistoryWrapperOuter:hover::before {
	position: absolute;
	content: '';
	width: calc(var(--section-width) - 2 * var(--section-padding) - 2 * var(--section-border));
	height: calc(var(--section-height) - 2 * var(--section-padding) - 2 * var(--section-border));
	border: var(--section-border) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
	z-index: 11;

}

.CallerHistoryTable {
	table-layout: fixed;
	width: 100%;
	border-collapse: collapse;
	word-wrap: break-word;
}

.CallerHistoryTable > thead th:last-child {
	width: 40%;
}

.CallerHistoryTable > thead > tr {
	background-image: linear-gradient(to top, var(--primary-color), var(--primary-color), var(--light-primary-color));
}

.CallerHistoryTable > thead > tr > th {
	padding: 0.25vmin;
	border: calc(1px + 0.03vmin) var(--normal-background-color) solid;
	font-style: normal;
	font-weight: 300;
	color: var(--white-color);
}

.CallerHistoryTable > tbody > tr {
	color: var(--extremely-light-background-color);
	font-style: normal;
	font-weight: 300;
	text-align: center;
}

.CallerHistoryTable > tbody > tr > td:last-child {
	text-align: justify;
}

.CallerHistoryTable > tbody > tr > td {
	padding: 0.5vmin;
}

.CallerHistoryTable > tbody > tr:nth-child(even) {
	background-color: var(--darker-background-color);
}

.CallerHistoryTable > tbody > tr:nth-child(odd) {
	background-color: var(--very-dark-background-color);
}


