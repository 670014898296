
#LoginWrapperOuter {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	overflow: hidden;
}

.CenteredSection {
	--section-width: 30vmin;
	--section-height: 40vmin;
	--section-padding: 0.50vmin;
	--section-border: calc(1px + 0.03vmin);
}

.CenteredSection > * {
	position: relative;
	z-index: 13;
}

#LoginWrapperInner img {
	box-sizing: border-box;
	width: 100%;
	height: 30%;
	object-fit: contain;
	padding-top: 0.55vmin;
}

#LoginWrapperInner form {
	box-sizing: border-box;
	display: flex;
	align-content: flex-start;
	flex-direction: column;
	width: 100%;
	height: 60%;
	padding: 0.7vmin;
}

#LoginWrapperInner > form input {
	appearance: none;
	box-sizing: border-box;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 0.25vmin solid var(--primary-color);
	border-top: 0.15vmin solid black;
	border-left: 0.15vmin solid black;
	border-right: 0.15vmin solid black;
	margin-top: 1vmin;
	padding: 2.2vmin 1vmin 2.2vmin 1vmin;
	width: 100%;
	height: 15%;
	font-size: 1.3vmin;
	color: var(--white-color);

	background-color: var(--very-dark-background-color);
}

#LoginWrapperInner > form > input:-webkit-autofill {
	-webkit-text-fill-color: var(--white-color) !important;
	box-shadow: 0 0 0px 100vmax var(--dark-background-color) inset !important;
	color: inherit !important;
}

#LoginWrapperInner > form > button {
	margin-top: 2vmin;
	width: 50%;
	font-size: 1.50vmin;
	margin-left: 25%;
	height: 10%;
}

.LoginLabel {
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
	width: 100%;
	height: 10%;
}

#LoginError {
	width: 100%;
	height: 4%;
}
