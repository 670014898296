#SettingsModal {
	--modal-width: calc(15vmin + 180px);
	--modal-height: calc(20vmin + 240px);
	--modal-padding: 0.5vmin;
	--modal-border: calc(1px + 0.1vmin);
}

#SettingsModal .ModalBody{
	position: relative;
	box-sizing: border-box;
	z-index: 13;
	width: 100%;
	height: 90%;
	padding: 2vmin 2vmin 0 2vmin;
}

#SettingsModal .ModalFooter {
	position: relative;
	z-index: 13;
	width: 100%;
	height: 10%;
}

.SettingsMenuPresentationWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
	height: 100%;
}

.SettingsMenuPresentationWrapper div {
	width: 100%;
	flex-grow: 1;
	color: var(--secondary-color);
}

.SettingsMenuPresentationWrapper select {
	flex-grow: 3;
	box-sizing: border-box;
	margin-top: 1vmin;
	margin-bottom: 1vmin;
	border: none;
	outline: none;
	box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(10px + 0.375vmin) black;
	background-image: linear-gradient(to top, var(--very-very-light-background-color), var(--extremely-light-background-color));
}

.SettingsMenuPresentationWrapper button {
	flex-grow: 2;
	box-sizing: border-box;
	margin-top: 1vmin;
	margin-bottom: 1vmin;
	border: none;
	outline: none;
	color: var(--bright-white-color);
	font-size: 1.7vmin;
	background-image: linear-gradient(to top, var(--primary-color), var(--light-primary-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 0.25vmin) black,
}

.SettingsMenuPresentationWrapper button:hover {
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--bright-white-color), 0 0 calc(15px + 0.7vmin) var(--very-light-primary-color);
}

.SettingsMenuPresentationWrapper button:active {
	box-shadow: inset 0 0 calc(10px + 0.25vmin) var(--dark-background-color);
	filter: brightness(75%);
	transform: translateY(3px);
}

#NewShowModal.ModalStyle {
	--modal-width: calc(25vmin + 300px);
	--modal-height: calc(2vmin + 100px);
	--modal-padding: 0.5vmin;
	--modal-border: calc(1px + 0.1vmin);
}

#NewShowModal .ModalBody {
	position: relative;
	z-index: 13;
	width: 100%;
	height: 50%;
}

#NewShowModal .ModalFooter {
	position: relative;
	z-index: 13;
	width: 100%;
	height: 50%;
}

#NewShowModal .ModalBody > div {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;	
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0.5vmin;
}

#NewShowModal input {
	flex-grow: 6;
	outline: none;
	background-color: var(--extremely-light-background-color);
	border: none;
	border-radius: calc(2px + 0.15vmin);
	font-size: calc(1.25vmin);
	padding-left: calc(2px + 0.25vmin);
}

#NewShowModal input:focus, #NewShowModal select:focus {
	border: calc(2px + 0.03vmin) solid var(--light-primary-color);
}

#NewShowModal select {
	flex-grow: 1;
	box-sizing: border-box;
	margin-left: 0.5vmin;
	font-size: calc(1.25vmin);
	background-image: linear-gradient(to top, var(--very-very-light-background-color), var(--extremely-light-background-color));
}

.EndShowModal.ModalStyle {
	--modal-width: calc(25vmin + 300px);
	--modal-height: calc(2vmin + 100px);
	--modal-padding: 0.5vmin;
	--modal-border: calc(1px + 0.1vmin);
}

.EndShowModal .ModalBody {
	position: relative;
	z-index: 13;
	width: 100%;
	height: 50%;
	text-align: center;
	color: var(--white-color);
	font-size: calc(1.5vmin);
}

.EndShowModal .ModalFooter {
	position: relative;
	z-index: 13;
	width: 100%;
	height: 50%;
}

.EndShowModal .ModalBody > div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: 100%;
}


.Header {
	display: flex;
	flex-direction: row;
	justify-content: center;
    position: absolute;
    top: 0;
    width: 60vw;
    height: calc(30px + 2vh - 2px - 0.15vmin);
    margin: 0;
    padding: 0;
	/*
	background-color: var(--very-dark-background-color);
	border-bottom-right-radius: 35% 100%;
	border-bottom-left-radius: 35% 100%;
	border-bottom: calc(2px + 0.15vmin) solid var(--lighter-background-color);
	border-right: calc(2px + 0.15vmin) solid var(--lighter-background-color);
	border-left: calc(2px + 0.15vmin) solid var(--lighter-background-color);*/
	z-index: 10; 
	left: 20vw;
}

/*
@media (max-width: 1280px) {
	.Header {
		width: 640px;
		left: 320px;
	}

}*/

.HeaderNameWrapper {
	flex-grow: 1;
	text-align: center;
	padding-top: 0.25vh;
	max-width: 20%;
}

.HeaderDispName {
	font-size: 1.3vmin;
	color: var(--secondary-color);
}

.HeaderCompanyName {
	font-size: 1.00vmin;
	color: var(--primary-color);
}

.SettingsButtonHeaderWrapper {
	display: flex;
	justify-content: center;
	flex-shrink: 1;
	width: 45%;
	padding: 0.25vmin;
	height: 100%;
}


.SettingsButton {
	position: relative;
	display: flex;
	justify-content: center;
	align-content: center;
	align-self: center;
	background-image: linear-gradient(to top right, var(--lighter-background-color), var(--very-light-background-color));
	height: 50%;
	width: 90%;
	padding: 0.45vmin;
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(2px + 0.05vmin) black,
		inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(2px + 0.05vmin) var(--darker-background-color);
	border: calc(1px + 0.03vmin) solid black;
}

.SettingsButton div {
	align-self: center;
}

.SettingsImage {
	object-fit: contain;
	height: 100%;
	width: 100%;
}

.SettingsButton:hover {
	filter: brightness(125%);
}

.SettingsButton:hover > .SettingsImage {
	filter: invert(100%);
}

.SettingsButton:active {
	filter: brightness(75%);
	box-shadow: inset 0 0 calc(7px + 0.275vmin) black;
	transform: translateY(3px);
}

.ShowHeaderWrapper {
	overflow: hidden;
	display: flex;
	justify-content: center;
	flex-grow: 5;
	padding: 0.5vmin;
	border: calc(1px + 0.10vmin) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 0.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
	border-bottom-left-radius: 30% 40%;
	border-bottom-right-radius: 30% 40%;
	max-width: 60%;
}

.ShowHeaderWrapper > select {
	appearance: none;
	outline: none;
	flex-grow: 5;
	margin-left: 0.5vmin;
	margin-right: 0.5vmin;
	border-bottom-right-radius: 80% 20%;
	border-bottom-left-radius: 80% 20%;
	border: calc(1px + 0.03vmin) solid black;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	background-color: black;
	text-align-last: center;
	font-size: calc(1.25vmin);
	max-width: 60%;
	color: var(--white-color);
	background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
	transition: 0.5s;
	animation: primary-text-glow 4.5s ease-in-out infinite alternate;
	transform: rotateZ(360deg);
}

.ShowHeaderWrapper > select:hover {
	background-image: linear-gradient(to top, var(--dark-background-color), var(--normal-background-color));
	border: calc(2px + 0.03vmin) solid var(--very-light-primary-color);
}

.ShowHeaderWrapper > div {
	flex-grow: 1;
	height: 100%;
}

.ShowHeaderWrapper button {
	outline: none;
	height: 85%;
	width: calc(100% - (10px + 0.5vmin));
	font-size: 1.3vmin;
	border: none;
	color: var(--white-color);
	box-sizing: border-box;
}

.ShowHeaderWrapper > div:first-of-type button:hover {
	color: var(--bright-white-color);
	filter: brightness(125%);
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--light-active-color), 0 0 calc(15px + 0.7vmin) var(--active-color);
}


.ShowHeaderWrapper > div button {
	padding: 0;
	width: 100%;
	text-align: center;
	background-color: var(--normal-background-color);
}

.ShowHeaderWrapper > div:first-of-type button {
	border-bottom-left-radius:  100% 50%;
	box-shadow: inset 0 0 calc(7px + 0.275vmin) var(--dark-active-color);
	background-image: linear-gradient(to top, var(--dark-active-color), var(--active-color));
	background-color: var(--dark-active-color);
}

.ShowHeaderWrapper > div:first-of-type {
	padding-right: calc(3px + 0.2vmin);
}

.ShowHeaderWrapper > div:last-of-type {
	padding-left: calc(3px + 0.2vmin);
}

.ActiveEndShowButton {
	border-bottom-right-radius: 100% 50%;
	box-shadow: inset 0 0 calc(7px + 0.275vmin) var(--inactive-color);
	background-image: linear-gradient(to top, var(--dark-inactive-color), var(--inactive-color));
	background-color: var(--dark-inactive-color);
}

.ActiveEndShowButton:hover {
	color: var(--bright-white-color);
	filter: brightness(125%);
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--light-inactive-color), 0 0 calc(15px + 0.7vmin) var(--inactive-color);
}


.ShowHeaderWrapper > div:first-of-type button:active, .ActiveEndShowButton:active {
	filter: brightness(80%);
	box-shadow: inset 0 0 calc(7px + 0.275vmin) black;
	transform: translateY(1px);
}

.ModalStyle {
	position: fixed;
	top: calc((100vh - var(--modal-height))/2);
	left: calc((100vw - var(--modal-width))/2);
	width: calc(var(--modal-width) - 2 * var(--modal-padding) - 2 * var(--modal-border));
	height: calc(var(--modal-height) - 2 * var(--modal-padding) - 2 * var(--modal-border));
	padding: var(--modal-padding);
	border: var(--modal-border) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--light-background-color), var(--very-light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(20px + 2.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
	z-index: 12;
}

.ModalOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 11;
	background-image: linear-gradient(to top, rgba(20,20,20,0.7), rgba(40,40,40,0.7));
}

.ModalStyle:before {
	position: absolute;
	content: '';
	box-sizing: border-box;
	width: calc(var(--modal-width) - 2 * var(--modal-padding) - 2 * var(--modal-border));
	height: calc(var(--modal-height) - 2 * var(--modal-padding) - 2 * var(--modal-border));
	border: var(--modal-border) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
	z-index: 11;

}

.ModalStyle .ModalFooter {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.ModalStyle .ModalClose, .ModalStyle .ModalOk {
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	margin: calc(0.5vmin + 5px);
	color: var(--very-dark-background-color);
	transition: 0.5s;
	width: 35%;
}

.ModalStyle .ModalClose:hover, .ModalStyle .ModalOk:hover {
	color: var(--bright-white-color);
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--light-primary-color), 0 0 calc(15px + 0.7vmin) var(--primary-color);
}

.ModalStyle .ModalClose:active, .ModalStyle .ModalOk:active {
	box-shadow: inset 0 0 calc(10px + 0.25vmin) black;
	filter: brightness(75%);
	transform: translateY(calc(-2px + 0.25vmin));
}

.ModalStyle .ModalFooter div div {
	width: 100%;
}

.ModalStyle .ModalClose {
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
}


.ModalStyle .ModalOk {
	background-image: linear-gradient(to top, var(--dark-active-color), var(--active-color));
}

.EndShowModal.ModalStyle .ModalOk {
	background-image: linear-gradient(to top, var(--dark-inactive-color), var(--inactive-color));
	color: var(--white-color);
}

.EndShowModal.ModalStyle .ModalOk:hover {
	color: var(--bright-white-color);
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--light-inactive-color), 0 0 calc(15px + 0.7vmin) var(--inactive-color);
}

.InactiveEndShowButton {
	background-image: linear-gradient(to top, var(--normal-background-color), var(--lighter-background-color)) !important;
	color: var(--very-light-background-color) !important;
}


#ShowLinkModal.ModalStyle {
	--modal-width: calc(25vmin + 300px);
	--modal-height: calc(5vmin + 200px);
	--modal-padding: 0.5vmin;
	--modal-border: calc(1px + 0.1vmin);
}

#ShowLinkModal .ModalBody {
	position: relative;
	z-index: 13;
	width: 100%;
	height: 80%;
}

#ShowLinkModal .ModalFooter {
	position: relative;
	z-index: 13;
	width: 100%;
	height: 20%;
}

#ShowLinkModal .ModalBody > div {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;	
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 0.5vmin;
}

#ShowLinkModal input {
	flex-grow: 6;
	outline: none;
	background-color: var(--extremely-light-background-color);
	border: none;
	border-radius: calc(2px + 0.15vmin);
	font-size: calc(1.25vmin);
	padding-left: calc(2px + 0.25vmin);
}

#ShowLinkModal input:focus, #ShowLinkModal select:focus {
	border: calc(2px + 0.03vmin) solid var(--light-primary-color);
}

#ShowLinkModal select {
	flex-grow: 1;
	box-sizing: border-box;
	margin-left: 0.5vmin;
	font-size: calc(1.25vmin);
	background-image: linear-gradient(to top, var(--very-very-light-background-color), var(--extremely-light-background-color));
}

.ShowLinkPresentationWrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 100%;
	height: 100%;
}

.ShowLinkPresentationWrapper div {
	display: flex;
	justify-content: space-evenly;
	width: 100%;
	flex-grow: 1;
	color: var(--secondary-color);
}

.ShowLinkPresentationWrapper select {
	flex-grow: 3;
	box-sizing: border-box;
	margin-top: 1vmin;
	margin-bottom: 1vmin;
	border: none;
	outline: none;
	box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(10px + 0.375vmin) black;
	background-image: linear-gradient(to top, var(--very-very-light-background-color), var(--extremely-light-background-color));
}

.ShowLinkPresentationWrapper input {
	margin-left: 0.5vmin;
	margin-right: 0.5vmin;
	margin-top: 1vmin;
	margin-bottom: 1vmin;
}

.ShowUrlWrapper {
	display: flex;
}

.ShowUrlWrapper div {
	flex-grow: 0;
	flex-shrink: 1;
	color: var(--primary-color);
	font-size: calc(10px + 1.5vmin);
	width: auto;
	justify-self: center;
	align-self: center;
	margin-right: 0.8vmin;
}

.ShowUrlWrapper div:hover {
	color: var(--light-primary-color);
}

.ShowUrl {
	border: calc(1px + 0.05vmin) solid var(--primary-color) !important;
	background: transparent !important;
	color: var(--primary-color);
	flex-grow: 1;
}

.HeaderSettingsButtons {
	display: flex;
	flex-shrink: 1;
	flex-grow: 0;
	max-width: 20%;
}

