.QueueTab {
	position: relative;
	overflow: hidden;
    width: calc(100% - 1.5vmin - 2*(1px + 0.10vmin));
	height: calc(100% - 1.5vmin - 2*(1px + 0.10vmin));
	padding: 0.5vmin;
	margin: 0.25vmin;
	border-radius: calc(20px + 0.25vmin);
	border: calc(1px + 0.10vmin) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 0.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
	z-index: 2;
}

.QueueTab::before {
	position: absolute;
	content: '';
	overflow: hidden;
    width: calc(100% - 1vmin - 2*(1px + 0.10vmin));
	height: calc(100% - 1vmin - 2*(1px + 0.10vmin));
	border: calc(1px + 0.10vmin) solid var(--very-dark-background-color);
	border-radius: calc(15px + 0.15vmin);
	background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
	z-index: -1;
}

.QueueHeader {
	overflow: hidden;
    width: 95%;
	height: 6%;
	padding-left: 2.5%;
	padding-right: 2.5%;
	padding-bottom: 3%;
	padding-top: 3%;
	background-image: linear-gradient(to right bottom, var(--light-background-color), var(--lighter-background-color));
	border-top-right-radius: calc(15px + 0.15vmin);
	border-top-left-radius: calc(15px + 0.15vmin);
}

.QueueHeader .TabContainer {
	overflow: hidden;
}

.QueueHeader .TabContainer .Tab {
	overflow: hidden;
}

.QueueBody {
	overflow: hidden;
	box-sizing: border-box;
	padding-left: calc(3px + 0.25vmin);
	padding-right: calc(3px + 0.25vmin);
	padding-top: calc(3px + 0.25vmin);
	padding-bottom: 1.0vh;
	width: 100%;
	height: 92%;
}