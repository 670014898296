.GuestMediaSection {
	width: calc(100% - 6px - 1.5vw);
	height:calc(55% - calc(35px + 2.5vh));
	margin: 0;
	padding-top: calc(35px + 2.5vh);
	padding-left: calc(3px + 0.75vw);
	padding-right: calc(3px + 0.75vw);
}

video {
    object-fit: contain;
}

.AutoForwardWarning {
	color: var(--inactive-color);
	width: 100%;
}

.CustomVideoWrapperOuter {
	width: 100%;
	height: 100%;
}

.CustomVideoWrapper {
	position: relative;
	width: calc(100% - 10px - 1vmin);
	height: calc(95% - 10px - 1vmin);
	left: 0;
	top: 0;
	padding: calc(5px + 0.5vmin);
	margin: 0;
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 0.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
}

.CustomVideoWrapper::before {
	position: absolute;
	content: '';
	overflow: hidden;
	width: calc(100% - 10px - 1vmin);
	height: calc(100% - 10px - 1vmin);
	background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.RemoteVideo {
	position: absolute;
	box-sizing: border-box;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: calc(6px + 1.1vmin);
	z-index: 1;
}

.LocalVideo {
	position: absolute;
	left: 2vmin;
	bottom: 2vmin;
	background-color: #000000;
	border: 0.35vmin solid var(--light-background-color);
	width: 16vmin;
	height: 9vmin;
	z-index: 3;
}

.VideoFraming {
	position: absolute;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: calc(6px + 1.1vmin);
	top: 0;
	left: 0;
	object-fit: contain;
	z-index: 2;
	filter: hue-rotate(220deg) brightness(250%);
}


.ShowBrowserInfoWrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: absolute;
	box-sizing: border-box;
	right: calc(5px + 0.5vmin);
	top: calc(5px + 0.5vmin);
	z-index: 2;
	width: 4%;
	height: 5%;
	background-color: rgba(0,0,0,0.5);
}
.ShowBrowserInfoWrapper:hover {
	z-index: 2;
	width: 20%;
	height: calc(100% - 10px - 1.0vmin);
	border-left: calc(2px + 0.5vmin) solid var(--lighter-background-color);
}

.ShowBrowserInfoWrapper .BrowserInfoHeader {
	color: transparent;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(to top, var(--primary-color), var(--light-primary-color));
}

.ShowBrowserInfoWrapper:hover .BrowserInfoHeader {
	height: 5%;
	width: 20%;
	align-self: flex-end;
	color: var(--bright-white-color);
}

.ShowBrowserInfoWrapper #GuestBrowserInfoContainer {
	height: 0%;
	width: 100%;
	overflow: hidden;
	transition: 0s;
}

.ShowBrowserInfoWrapper:hover #GuestBrowserInfoContainer {
	height: 96%;
	overflow: visible;
	transition: 0.5s;
}

.BrowserInfoHeader {
	border: calc(1px + 0.03vmin) solid var(--dark-background-color);
}


#GuestBrowserInfoContainer {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

#GuestBrowserInfoContainer select {
	align-self: center;
	width: 90%;
	height: 8%;
	margin-top: 1.5%;
	margin-bottom: 1.5%;
}

#GuestBrowserInfoContainer div {
	text-align: center;
	font-size: calc(1.36vmin);
	color: var(--primary-color);
}

#GuestBrowserInfoContainer div:first-of-type {
	font-size: calc(1.36vmin);
	color: var(--secondary-color);
}

#GuestBrowserInfoContainer select {
	border: none;
	outline: none;
	box-shadow: calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(10px + 0.375vmin) black;
	background-image: linear-gradient(to top, var(--very-very-light-background-color), var(--extremely-light-background-color));
}

#GuestBrowserInfoContainer select:hover {
	box-shadow: inset 0 0 calc(30px + 0.75vmin) var(--very-light-primary-color);
}

.BrowserInfoRefreshButton {
	align-self: center;
	width: calc(100% - 10px - 1vmin);
	padding: calc(5px + 0.5vmin);
	margin-top: 1.5%;
	border: none;
	outline: none;
	color: var(--bright-white-color);
	background-image: linear-gradient(to top, var(--primary-color), var(--light-primary-color));
	background-color: var(--primary-color);
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 0.25vmin) black,
}

.BrowserInfoRefreshButton:hover {
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--bright-white-color), 0 0 calc(15px + 0.7vmin) var(--very-light-primary-color);
}

.BrowserInfoRefreshButton:active {
	box-shadow: inset 0 0 calc(10px + 0.25vmin) var(--dark-background-color);
	filter: brightness(75%);
	transform: translateY(3px);
}

.BrowserInfoHeaderImage {
	width: 100%;
	height: 100%;
	object-fit: contain;
	box-sizing: border-box;
	padding: 0.15vmin;
	filter: invert(100%);
}

.ToggleFramingButton {
	position: absolute;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	outline: none;
	padding: none;
	top: calc(5px + 0.5vmin);
	left: calc(5px + 0.5vmin);
	width: 4.5%;
	height: 5%;
	background-image: linear-gradient(to top, var(--dark-secondary-color), var(--secondary-color));
	background-color: black;
	z-index: 4;
	color: var(--white-color);
	border: calc(1px + 0.03vmin) solid black;
	transition: 0.25s;
	font-size: calc(0.9vmin);
	text-align: center;
}

.ToggleFramingButton:hover {
	filter: brightness(125%);
	color: var(--bright-white-color);
}

.ToggleFramingButton:active {
	box-shadow: inset 0 0 calc(10px + 0.25vmin) var(--dark-background-color);
	filter: brightness(75%);
	transform: translateY(1px);
}

.ActiveFraming {
	background-image: linear-gradient(to top, var(--dark-inactive-color), var(--inactive-color));
}

.ToggleFramingButton > div {
	width: 100%;
}

.CallStateWrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	position: absolute;
	overflow: scroll;
	transition: 0.5s;
	top: calc(5px + 0.5vmin + 6%);
	left: calc(4px + 0.6vmin);
	color: white;
	background-image: linear-gradient(to top, var(--primary-color), var(--light-primary-color));
	border: calc(1px + 0.10vmin) solid black;
	border-left: none;
	width: 1.8%;
	height: 60%;
	z-index: 5;
}

.CallStateWrapper > div {
	transition: 0.2s;
	opacity: 0;
}

.CallStateWrapper > .ExpandArrow {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 0%;
	text-align: center;
	line-height: 0;
	align-self: center;
	justify-self: center;
	display: block;
	opacity: 1;
	background-color: red;
}

.CallStateWrapper:hover > div {
	display: block;
	transition-delay: 0.5s;
	transition: 1.5s;
	opacity: 1;
}

.CallStateWrapper:hover > .ExpandArrow {
	display: none;
	flex-grow: 1;
}

.CallStateWrapper:hover {
	width: 30%;
	background-image: none;
	background-color: rgba(30,30,30,0.8);
	border: calc(3px + 0.3vmin) solid var(--lighter-background-color);
	border-left: none;
	padding-top: 1.5vmin;
	padding-left: 1.5vmin;
}

