
.ModifyGuestSection {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}


.MoveGuestSection {
	width: 100%;
	height: 13%;
	margin: 0;
	padding: 0;
}

.GuestDemographicsSection {
	position: relative;
	width: 100%;
	height: 32%;
	margin: 0;
	padding: 0;
}

.GuestDemographicsTabWrapper {
	width: 100%;
	height: 10%;
}

.MoveGuestButtonOuter {
	position: relative;
	width: calc(20% - 1px - 1vmin);
	border-right: 1px solid var(--normal-background-color);
	height: calc(100% - 1vmin);
	padding: 0.5vmin;
	font-weight: 700;
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
	border-top-right-radius: calc(20px + 2vmin) 100%;
	border-top-left-radius: calc(20px + 2vmin) 100%;
	border-bottom-right-radius: calc(20px + 2vmin) 100%;
	border-bottom-left-radius: calc(20px + 2vmin) 100%;
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(10px + 0.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
}

.MoveGuestButtonOuter:before {
	position: absolute;
	content: '';
	width: calc(100% - 1px - 1vmin);
	height: calc(100% - 1vmin);
	border-top-right-radius: calc(20px + 2vmin) 100%;
	border-top-left-radius: calc(20px + 2vmin) 100%;
	border-bottom-right-radius: calc(20px + 2vmin) 100%;
	border-bottom-left-radius: calc(20px + 2vmin) 100%;
	z-index: -1;
}

.ConnectButtonCanDisconnect {
	background-color: var(--inactive-color);
	color: var(--bright-white-color);
}

.MoveGuestButton:last-of-type {
	width: 20%;
	border: none;
}

.ValidMoveGuestButton:active {
	color: var(--primary-color);
}


.MoveGuestButtonInner {
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	width: calc(100 - 2px - 0.06vmin);
	height: calc(100% - 2px - 0.06vmin);
	border-top-right-radius: calc(20px + 2vmin) 100%;
	border-top-left-radius: calc(20px + 2vmin) 100%;
	border-bottom-right-radius: calc(20px + 2vmin) 100%;
	border-bottom-left-radius: calc(20px + 2vmin) 100%;
	border: calc(1px + 0.03vmin) solid var(--dark-background-color);
	transition: 0.5s;
}

.InactiveMoveGuestButton .MoveGuestButtonInner {
	color: var(--dark-background-color);
	background-image: linear-gradient(to top, var(--dark-background-color), var(--normal-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
}

.ValidMoveGuestButton .MoveGuestButtonInner{
	background-image: radial-gradient(var(--very-light-background-color), var(--lighter-background-color));
	box-shadow: inset 0 0 calc(5px + 0.15vmin) black;
	color: var(--dark-background-color);
}

.ValidMoveGuestButton:hover .MoveGuestButtonInner {
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--light-primary-color), 0 0 calc(15px + 0.7vmin) var(--primary-color);
	color: var(--bright-white-color);
}

.ValidMoveGuestButton:active .MoveGuestButtonInner  {
	background-image: linear-gradient(to top, var(--dark-background-color), var(--normal-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
	color: var(--dark-white-color);
}

.ConnectButton.ValidMoveGuestButton .MoveGuestButtonInner {
	background-image: radial-gradient(var(--light-active-color), var(--dark-active-color));
}

.ConnectButton.ValidMoveGuestButton:active .MoveGuestButtonInner {
	background-image: linear-gradient(to top, var(--dark-active-color), var(--active-color));
}

.ConnectButton.ValidMoveGuestButton:hover .MoveGuestButtonInner {
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--light-active-color), 0 0 calc(15px + 0.7vmin) var(--active-color);
}

.DisconnectButton.ValidMoveGuestButton .MoveGuestButtonInner {
	background-image: radial-gradient(var(--light-inactive-color), var(--dark-inactive-color));
}

.DisconnectButton.ValidMoveGuestButton:active .MoveGuestButtonInner {
	background-image: linear-gradient(to top, var(--dark-inactive-color), var(--inactive-color));
}

.DisconnectButton.ValidMoveGuestButton:hover .MoveGuestButtonInner {
	text-shadow: 0 0 calc(5px + 0.3vmin) var(--light-inactive-color), 0 0 calc(15px + 0.7vmin) var(--inactive-color);
}


.MoveGuestButtonContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

.MoveGuestButtonContainerInner {
	width: 100%;
	display: flex;
	flex-grow: 1;
	flex-direction: row;
	justify-content: space-evenly;
	padding-top: 0.55vh;
	padding-bottom: 0.55vh;
}