* {
	--normal-background-color: #403f47;
	--lighter-background-color: #4e4e57;
	--light-background-color: #60606b;
	--very-light-background-color: #858494;
	--very-very-light-background-color: #bfbdd1;
	--extremely-light-background-color: #f3f3f3ff;
	--darker-background-color: #303036;
	--dark-background-color: #222226;
	--very-dark-background-color: #19191c;
	--primary-color: #0985cf;
	--light-primary-color: #0ea7ff;
	--very-light-primary-color: #bbdbff;
	--secondary-color: #09cf52;
	--dark-secondary-color: #00af32;
	--dark-white-color: #aaaaba;
	--white-color: #dddde5;
	--bright-white-color: #f3f3f3ff;
	--very-dark-white-color: #888890;
	--error-color: #cf1d09;
	--active-color: #09cf52;
	--dark-active-color: #089439;
	--light-active-color: #0ce357; 
	--inactive-color: #cf1d09;
	--light-inactive-color: #e81d06;
	--dark-inactive-color: #851104;
	font-family: 'Mada', sans-serif;
	font-size: calc(1.525vmin);
}

/*
@keyframes primary-text-glow {
	from {
		text-shadow: 0 0 calc(16px + 1vmin) var(--light-primary-color), 0 0 calc(40px + 3vmin) var(--primary-color);
		color: var(--very-light-primary-color);
	}
	to {
		color: var(--very-very-light-background-color);
	}
}

@keyframes primary-background-glow {
	from {
		box-shadow: 0 0 calc(16px + 1vmin) var(--light-primary-color), 0 0 calc(40px + 3vmin) var(--primary-color);
		background-color: var(--very-light-primary-color);
	}
	to {
		background-color: var(--very-very-light-background-color);
	}
}
*/
/* For switching between animations to synchronize things */
/*
@keyframes primary-background-glow-alt {
	from {
		box-shadow: 0 0 calc(16px + 1vmin) var(--light-primary-color), 0 0 calc(40px + 3vmin) var(--primary-color);
		background-color: var(--very-light-primary-color);
	}
	to {
		background-color: var(--very-very-light-background-color);
	}
}*/



.custom-active-button {
	background-image: linear-gradient(to top, var(--dark-active-color), var(--active-color));
	background-color: black;
	color: var(--bright-white-color);
	border: none;
}

.custom-active-button:hover {
	filter: brightness(120%);
}

.custom-active-button:active {
	filter: brightness(85%);
	transform: translateY(2px);
}

.large-label {
	font-size: calc(2.54vmin);
	color: var(--white-color);
	text-overflow: ellipsis;
}

.centered-text {
	text-align: center;
}

.error-font {
	font-size: calc(1.225vmin);
	color: var(--error-color);
}

*::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

/*.Body {
	min-height: 720px;
	min-width: 1280px;
}*/

#root {
	position: absolute;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
}

.ConnectionFailure {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	color: var(--active-color);
	width: 100vw;
	--section-width: 30vmin;
	--section-height: 40vmin;
	--section-padding: 0.5vmin;
	--section-border: calc(1px + 0.03vmin);
}

.ConnectionFailure div {
	font-size: calc(1.602vmin);
}


.CenteredSection {
	position: fixed;
	top: calc((100vh - var(--section-height))/2);
	left: calc((100vw - var(--section-width))/2);
	width: calc(var(--section-width) - 2 * var(--section-padding) - 2 * var(--section-border));
	height: calc(var(--section-height) - 2 * var(--section-padding) - 2 * var(--section-border));
	padding: var(--section-padding);
	border: var(--section-border) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--light-background-color), var(--very-light-background-color));
	box-shadow: calc(2px + 0.10vmin) calc(2px + 0.10vmin) calc(20px + 2.25vmin) black,
				inset calc(-2px - 0.1vmin) calc(-2px + -0.1vmin) calc(10px + 0.25vmin) var(--dark-background-color);
	z-index: 12;
}

.CenteredSection:before {
	position: absolute;
	content: '';
	box-sizing: border-box;
	width: calc(var(--section-width) - 2 * var(--section-padding) - 2 * var(--section-border));
	height: calc(var(--section-height) - 2 * var(--section-padding) - 2 * var(--section-border));
	border: var(--section-border) solid var(--very-dark-background-color);
	background-image: linear-gradient(to top, var(--very-dark-background-color), var(--dark-background-color));
	box-shadow: inset calc(1px + 0.05vmin) calc(1px + 0.05vmin) calc(20px + 0.75vmin) black;
	z-index: 11;

}

.NoiseBackground {
	background: url('../Assets/BackgroundNoise.png'),
		linear-gradient(to bottom right, var(--darker-background-color), var(--dark-background-color), var(--very-dark-background-color));
	background-repeat: repeat;
}
