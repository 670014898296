.Main {
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
}

.CenterModifyGuest {
    width: 60%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.CenterQueue {
	width: 20%;
	/*min-width: 300px;*/
	height: 100%;
	margin: 0;
}

.Body {
    display: flex;
    align-content: flex-start;
    flex-direction: row;
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
}