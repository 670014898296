.TabContainer {
	display: flex;
	align-items: stretch;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	height: 100%;
}

.Tab:first-child {
	border-top-left-radius: calc(10px + 1vmin) 100%;
	border-bottom-left-radius: calc(10px + 1vmin) 100%;
	border-left: calc(1px + 0.15vmin) solid black;
}

.Tab:last-child {
	border-top-right-radius: calc(10px + 1vmin) 100%;
	border-bottom-right-radius: calc(10px + 1vmin) 100%;
	border-right: calc(1px + 0.15vmin) solid black;
}

.Tab {
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	text-align: center;
	flex: 1;
	line-height: 100%;
	font-weight: 700;
	border-top: calc(1px + 0.15vmin) solid black;
	border-bottom: calc(1px + 0.15vmin) solid black;
	border-left: calc(1px + 0.03vmin) solid black;
	border-right: calc(1px + 0.03vmin) solid black;
	color: var(--dark-background-color);
	font-size: calc(1.5vmin);
	transition: 1s;
	/*border-right: calc(0.15vmin + 1px) solid var(--very-light-background-color);*/
}

.TabInactive {
	background-image: linear-gradient(to top, var(--lighter-background-color), var(--light-background-color));
}

.TabActive {
	background-color: var(--normal-background-color);
	color: var(--very-light-primary-color);
	box-shadow: inset 0 0 calc(10px + 0.75vmin) 0 black;
}

.TabActive .TabName {
	animation: primary-text-glow 4.5s ease-in-out infinite alternate;
	transform: rotateZ(360deg);
}

.TabActive .QueueCount {
	animation: primary-text-glow 4.5s ease-in-out infinite alternate;
	transform: rotateZ(360deg);
	background-image: radial-gradient(var(--normal-background-color), var(--dark-background-color));
}

.QueueCount {
	border-radius: 40%;
	align-self: center;    
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 2%;
	padding-bottom: 1.5%;
	color: var(--very-dark-background-color);
}

.QueueCount {
	color: var(--very-light-background-color);
	background-image: radial-gradient(var(--dark-background-color), var(--very-dark-background-color));
}